import DOMPurify from 'dompurify';
import WebFont from 'webfontloader';


// template base url. Relative urls in templates are replaced with this
let currentBaseUrl = null;
// object for storing globals added by template, for deleting them on cleanup
const globals = {};
// path to templates when running CE locally, but loading a remote template
let devRemotePath = null;



// built-ins to be overridden
const consoleLog = console.log;
let innerHTMLSetter;
let styleGetter;
let insertBefore;

// when not running on local, use Content platform API to get files pointing to relative URLs containing /ws-overrides/
let copOverrideUrl = '';
let copWorkspaceId = '';
let isLocal = false;

const isUrlAbsolute = (url) => url.startsWith('http://') || url.startsWith('https://') || url.startsWith('data:');

// converts relative (to template) url to absolute
const makeUrlAbsolute = (url, overrideBaseUrl) => {
    if (!url || typeof url !== 'string') return '';

    let u = url.trim();
    const ws = 'ws-overrides/';
    const n = u.indexOf(ws);
    if (n >= 0) {
        if (isLocal) {
            return devRemotePath && !u.includes(devRemotePath) ? `${devRemotePath}${u.substring(n)}` : u;
        }
        if (u.startsWith(copOverrideUrl)) return u;
            else if (copOverrideUrl) {
                // copOverrideUrl = `https://app.contentcreator.zetadisplay.dev/templates/${sourcePath}`;
                // https://app.contentcreator.zetadisplay.dev/templates/b10b206e-646b-4939-bfbd-5523e82c743e/ws-overrides/wso-css/custom.js?V=638312619440000000&WS=88b50e82-b480-409e-9ffd-385d191f8741
                u = u.substring(n);
                const ret =  `${copOverrideUrl}/${u}${u.includes('?') ? '&' : '?'}WS=${copWorkspaceId}`;
                console.log('%cOVERRIDE:', 'background-color:purple;color:white;', u )//, '=>', ret);
                return `${ret}&t=${new Date().getTime()}`
                }
    }
    if (isUrlAbsolute(u)
        || (devRemotePath && u.startsWith(devRemotePath))
        ) return u;
    u = new URL(u, overrideBaseUrl || currentBaseUrl).href;
    return devRemotePath ? u.substring(u.indexOf(devRemotePath)) : u;
};

/**
 * Checks if element is related to template (as opposed part of CE UI)
 * for modifying relative URLs
 * @param element
 * @returns {*|boolean|string}
 */
const getModifiedElementType = (element) => {
    if (element.closest('#zd_se_spot')) return 'template'; // =child of template root div
    return element.closest('[id^=SE_ImageThumbnail]') // =child of image select component preview img
        || element.closest('[id^=SE_HTML]') // =child of HTML component
        || element.closest('.cxe-editor-ui') // =custom UI component rendered by template
        || element.closest('[data-editor-ui]') // =another way of specifying custom UI component rendered by template
        || element.getAttribute('data-zd-imported') !== null // =is imported by template
};




const convertCSSurl = (styleSheet, propValue) => {
    if (typeof propValue !== 'string') return propValue;

    const regex = /url\((['"]?)(.*?)\1\)/g;
    let modifiedStyle = propValue;

    let match;
    while ((match = regex.exec(propValue)) !== null) {
        const originalUrl = match[2];
        const modifiedUrl = makeUrlAbsolute(originalUrl, styleSheet?.href);
        modifiedStyle = modifiedStyle.replace(originalUrl, modifiedUrl);
    }
    return modifiedStyle;
}

const cssUrlRules = ['background', 'background-image', 'border', 'border-image', 'border-image-source', 'content',
    'cursor', 'filter', 'list-style-image', 'mask', 'mask-image', 'offset-path'];

const convertStyleSheetURLs = sheet => {
    let rules;
    try {
        rules = Array.from(sheet.cssRules);
    } catch (err) {
        // sheet.cssRules throws with an external stylesheet with no cssRules, e.g.
        // <link rel="stylesheet" href="https://use.typekit.net/lmc5fwz.css">
        // ==> ignore safely
        return;
    }
    try {
        rules.forEach((rule) => {
            if (!rule.style) return;
            // handle @font-face srcs in imported stylesheets
            const src = rule.cssText.includes('@font-face') && rule.style.getPropertyValue('src');
            if (src) {
                const lines = src.split(',');
                rule.style.setProperty('src', lines.map((str) => convertCSSurl(sheet, str)).join(',')
                );
                //console.log('>>>>>>>', rule.style.getPropertyValue('src'));
            }
            // handle other css props that may use url() function
            cssUrlRules.forEach(propName => {
                const propValue = rule.style.getPropertyValue(propName);
                if (propValue) {
                    const converted = convertCSSurl(sheet, propValue);
                    if (propValue !== converted) {
                        rule.style.setProperty(propName, converted);
                        //console.log('>>>\n', rule.cssText);
                    }
                }
            })
            // TODO: support @import url()
            // @counter-style : url() not supported (browser support??)
        });
    } catch (err) {
        console.log('Error processing stylesheet: ', sheet, '>>>', err.message);
    }
}

const convertNodeUrls = (zdlib, node, force) => {
        if (node.tagName === 'STYLE') {
            if (node.sheet) convertStyleSheetURLs(node.sheet);
            return;
        }
        if (node.nodeType !== Node.ELEMENT_NODE) return;
        const images =
            node.tagName === 'IMG' || node.tagName === 'VIDEO' || node.tagName === 'AUDIO' || node.tagName === 'OBJECT'
                ? [node]
                : Array.from(node.querySelectorAll('img,video,audio,object')) || [];
        images.forEach((element) => {
            const modifiedType = force ? 'template' : getModifiedElementType(element);
            //if (!modifiedType) console.log('¤¤¤¤++++', element, modifiedType);
            if (modifiedType) {
                const propName = element.tagName === 'OBJECT' ? 'data' : 'src';
                let src = element.getAttribute(propName);
                if (!src && modifiedType === 'template') src = element.getAttribute('data-src');
                void setSrcProp(zdlib, element, propName, src, modifiedType);
            }
        });
        const elements = [node].concat(Array.from(node.querySelectorAll('*')));
        elements.forEach((element) => {
            const modifiedType = force || getModifiedElementType(element);
            if (modifiedType) {
                const style = element.getAttribute('style');
                const converted = convertCSSurl(null, style)
                if (converted && converted !== style) element._setAttribute('style', converted)
            }
        });
}


/**
 * Imports a template. This is used in CE (templateProvider), and in Transcoder
 * @param zdlib ZDlib instance
 * @param baseUrl Url to template index.html
 * @param devRemoteTemplate True if loading a remote template on localhost
 * @param onBeginLoad Callback used by CE to reset UI
 * @param editor ZDLib.editor interface. Separately here for legacy reasons only
 * @param editorIntf Editor private interface (unavailable to templates)
 * @param workspaceId Id of workspace to get override URL from
 * @param sourcePath Path (guid) to template
 * @returns {Promise<boolean|*>} True if successful
 */
export let importTemplate;
importTemplate = async ({   zdlib,
                            baseUrl,
                            devRemoteTemplate,
                            onBeginLoad,
                            editor,
                            editorIntf,
                            workspaceId,
                            sourcePath,
                        }) => {
    if (!baseUrl) throw new Error('No template url specified');
    // on the first run, store all global names
    if (Object.keys(globals).length === 0) Object.keys(window).forEach((globalVar) => (globals[globalVar] = true));
    currentBaseUrl = baseUrl;
    zdlib.env.baseUrl = baseUrl;
    devRemotePath = devRemoteTemplate ? baseUrl.substring(baseUrl.indexOf('/templates')) : null;

    isLocal = zdlib.env.isLocal;

    // Use CoP API for ws-overrides
    if (!zdlib.env.isLocal) {
        copWorkspaceId = workspaceId;
        copOverrideUrl = `${document.location.origin}/templates/${sourcePath}`;
    }

    // fetch template index.html
    const url = `${devRemotePath || baseUrl}index.html?t=${new Date().getTime()}`;
    console.log(`%cOpen template at ${url}`, 'background-color:lightgreen');
    const ret = await fetch(url);
    if (ret.status !== 200) throw new Error(`Error fetching template (${ret.status})`);
    const res = await ret.text();

    // parse template
    const parser = new DOMParser();
    const doc = parser.parseFromString(res, 'text/html');
    if (!doc.getElementById('zd_se_spot')) throw new Error('Invalid template');

    if (onBeginLoad) onBeginLoad();
    // cleanup previous template
    zdlib.cleanup();
    // restore window.zdlib
    delete window.zdlib;
    window.zdlib = zdlib;
    globals.zdlib = true;
    // remove everything marked as imported by template
    Array.from(document.querySelectorAll('*[data-zd-imported]')).forEach((node) => {
        node.parentNode.removeChild(node);
    });
    // remove all added globals (delete isn't always possible, so it needs to be set undefined)
    Object.keys(window).forEach((globalVar) => {
        if (!globals[globalVar] && window[globalVar]) {
            try {
                delete window[globalVar];
                //console.log('-...', globalVar, '==> DELETED');
            } catch (err) {
                //console.log('-...', globalVar, '==> undefined:: ', err.message);
                if (isNaN(Number(globalVar))) window[globalVar] = undefined;
            }
        }
    });
    // clear template root element
    const rootElem = document.getElementById('zd_se_spot');
    Array.from(rootElem.childNodes).forEach((node) => {
        if (node !== null) rootElem.removeChild(node);
    });

    // end cleanup

    // modify url() functions in style attribute --> convert relative urls to absolute
    fixStyleUrls();
    // sanitize values going thru setter for innerHTML
    fixInnerHTML(zdlib);

    fixNodeMethods(zdlib);

    // modify native element prototypes so that setting relative urls on them get auto-fixed
    fixLocalUrls(zdlib, HTMLImageElement.prototype, 'src');
    fixLocalUrls(zdlib, HTMLVideoElement.prototype, 'src');
    fixLocalUrls(zdlib, HTMLAudioElement.prototype, 'src');
    fixLocalUrls(zdlib, HTMLLinkElement.prototype, 'href');
    fixLocalUrls(zdlib, HTMLObjectElement.prototype, 'data');
    // modify relative urls in XHR
    fixXmlHttpRequest();
    // TODO: handle HTMLSourceElement
    //fixLocalUrls(zdlib, HTMLSourceElement.prototype, 'src');

    // suppress console.log calls from zdlib/template
    fixConsole(zdlib, process.env.ENGAGE_ENV);
    // zdlib has a timer for debugging purposes
    zdlib.timer.start();
    // append elements from head + body
    // +import scripts

    const {templateName, imports, isZetacast} = getTemplateImports(doc);
    if (!templateName) throw new Error('No template constructor found - invalid template, or not found.');

    if (isZetacast) {
        // builtIn = legacy template: set zdlib.editor to undefined
        zdlib.editor = undefined;
        console.log('Zetacast template detected')
        void await import('./importBuiltIns.js');
        Object.keys(window).forEach(globalVar => {
            if (!globals[globalVar]) {
                globals[globalVar] = true;
                //console.log('+++++++', globalVar)
            }
        });
    }

    // add zdlib utility functions if needed
    if (!zdlib.importScript) zdlib.importScript = importScript;
    if (!zdlib.loadFonts) zdlib.loadFonts = loadFonts;
    if (!zdlib.utils.makeUrlAbsolute) zdlib.utils.makeUrlAbsolute = makeUrlAbsolute;

    // import all css+script elements found
    await Promise.all(
        imports.map((item) => {
            return item.type === 'js' ? importScript(item) : importCss(item);
        })
    );
    // run timeout to allow possible iifes in imported scripts to run
    await new Promise((resolve) => setTimeout(resolve));
    if (typeof window[templateName] !== 'function') throw new Error(`Invalid template constructor - ${templateName}:${typeof window[templateName]}`);
    // add style elements from loaded HTML head
    Array.from(doc.head.children)
        .concat(Array.from(doc.body.children))
        .forEach((node) => {
            if (node.tagName === 'STYLE') {
                node.setAttribute('data-zd-imported', '');
                document.head.appendChild(node);
            }
        });


    Array.from(document.styleSheets)
        .filter((sheet) => sheet.ownerNode.hasAttribute('data-zd-imported'))
        .forEach(convertStyleSheetURLs);

    // catch nodes added to the template (probably in template code)
    // look for images/videos with relative urls -> convert to absolute
    // + style elements -> convert relative urls in url() functions to absolute
    let observer = new MutationObserver(mutationList => {
        mutationList.forEach((mutation) => {
            mutation.addedNodes.forEach(node => {
                convertNodeUrls(zdlib, node);
            });
            mutation.removedNodes.forEach(node => {
                if (node.nodeType !== Node.ELEMENT_NODE) return;
                const images =
                    node.tagName === 'IMG' || node.tagName === 'VIDEO' || node.tagName === 'AUDIO' || node.tagName === 'OBJECT'
                        ? [node]
                        : Array.from(node.querySelectorAll('img,video,audio,object')) || [];
                images.forEach((element) => {
                    zdlib.assetLoader.onClear(element);
                })
            })
        });

    });
    observer.observe(document, {childList: true, subtree: true});
    // begin observing loaded assets: these may be in the imported HTML as well, so we need to start before loading the template
    zdlib.feedLoader.begin(editorIntf);
    zdlib.assetLoader.begin(editorIntf);
    // append HTML from imported template's root div
    Array.from(doc.getElementById('zd_se_spot').childNodes).forEach((node) => {
        rootElem.appendChild(node);
    });
    // this is needed, otherwise the asset loading above will show 404 errors in console
    await new Promise((resolve) => setTimeout(resolve));
    return await zdlib.loadTemplate(window[templateName], editor, editorIntf);

};

const getTemplateImports = (doc) => {
    let templateName = '';
    const imports = [];
    let isZetacast = false;
    // These are scripts loaded into legacy templates via <script> tags
    // They're not needed anymore: some are obsolete (IE11 polyfills etc), some are now included in the bundle
    const zetacastScripts = [
        'ZDLib.js',
        'mobx.umd.min.js',
        'mobx.umd.js',
        'mobx.umd_orig.js',
        'es6-promise.auto.min.js',
        'smap-shim.min.js',
        'webfontloader.js',
        'TweenMax.min.js',
        'fiveserver.js',
        'gsap.min.js',
    ];

    const getNodeAttributes = (node, omit = []) => node.getAttributeNames()
        .filter(name => !omit.includes(name))
        .map(name => ({name, value: node.getAttribute(name)}))


    for (const node of Array.from(doc.head.children)
        .concat(Array.from(doc.body.children))) {
        if (node.tagName === 'LINK') {
            if (node.rel === 'stylesheet' && node.getAttribute('href')) {
                imports.push({
                    type: 'css',
                    url: makeUrlAbsolute(node.getAttribute('href')),
                    attributes: getNodeAttributes(node, ['href', 'rel']),
                });
            } else {
                node.setAttribute('data-zd-imported', '');
                document.head.appendChild(node);
            }
        } else if (node.tagName === 'SCRIPT') {
            const src = node.getAttribute('src') || '';
            if (!src) {
                // find template function name
                // templates have a body.onload event handler where template is instantiated
                const search = 'new ZDLib';
                let n = node.text.indexOf(search);
                n = n >= 0 ? node.text.indexOf('(', n) : -1;
                if (n > 0) {
                    templateName = node.text.substring(n + 1, node.text.indexOf(')', n)).trim();
                }
                continue;
            }
            const zetacastScript = zetacastScripts.find((file) => src.includes(file));
            if (!zetacastScript) {
                imports.push({
                    type: 'js',
                    url: makeUrlAbsolute(src),
                    attributes: getNodeAttributes(node, ['src']),
                });
            } else {
                isZetacast = true;
            }
        }
    }
    return {templateName, imports, isZetacast};
}

/**
 * replace innerHTML with sanitized value
 * @param zdlib
 * @returns {*}
 */
const fixInnerHTML = (zdlib) => {
    if (innerHTMLSetter) return;
    innerHTMLSetter = Object.getOwnPropertyDescriptor(Element.prototype, 'innerHTML').set;
    Object.defineProperty(Element.prototype, 'innerHTML', {
        enumerable: true,
        set(value) {
            const t = this.closest('template:not([shadowrootmode])');
            // msallowcapture is used in some legacy Gauddi templates, but may not be needed
            const safe = DOMPurify.sanitize(value, { IN_PLACE: true, ADD_TAGS: t && ['animate', 'set'] /*, ADD_ATTR: ['msallowcapture']*/ });
            if (zdlib.env.isDev || zdlib.env.isTranscoding) {
                const rem = DOMPurify.removed;
                if (rem?.length) console.warn('html sanitized:', this, '\n=>\n', rem);
            }
            innerHTMLSetter.call(this, safe);
        },
    });
};

const fixNodeMethods = (zdlib) => {
    if (insertBefore) return;
    insertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, refNode) {
        //const old = zdlib.editor && newNode?.innerHTML;
        convertNodeUrls(zdlib, newNode, true);
        //if (old && old !== newNode.innerHTML) console.log('insertBefore =>', newNode);
        return insertBefore.call(this, newNode, refNode);
    };
}

/**
 * Modify HTML element's src prop value, to convert relative urls to absolute.
 * Also calls zdlib.assetLoader that makes sure all template assets are loaded before transcoding can begin
 * @param zdlib ZDlib instance
 * @param element HTML element
 * @param propName src for image, etc
 * @param value Prop value
 * @param forcedType If set, skips checking if element is related to template
 * @returns {Promise<void>} Needs to be async because some video handling is
 */
const setSrcProp = async (zdlib, element, propName, value, forcedType) => {
    const modifiedType = forcedType || getModifiedElementType(element);
    // don't do anything if the element is not related to the template
    // =it's in CE UI, a React element
    // or not in DOM
    if (!modifiedType) {
        // _setAttribute is the original setAttribute method on the element prototype
        element._setAttribute(propName, value);
        return;
    }
    const events = {
        IMG: 'load',
        VIDEO: zdlib.env.isTranscoding || zdlib.env.isPreviewing ? 'canplaythrough' : 'loadeddata',
        AUDIO: zdlib.env.isTranscoding || zdlib.env.isPreviewing ? 'canplaythrough' : 'loadeddata',
        OBJECT: 'load'
    };
    const { tagName } = element;
    let modifiedValue = makeUrlAbsolute(value) || '';
    const current = element.getAttribute(propName) || '';
    if (current === modifiedValue && !modifiedValue.includes('/ws-overrides/')) return;
    //console.log('#########', element, current , '=>',  modifiedValue)
    if (modifiedType === 'template') {
        element._setAttribute('data-is-asset', '');
        const onLoaded = (ev) => {
            ev.target.removeEventListener(events[tagName], onLoaded);
            ev.target.removeEventListener('error', onError);
            zdlib.assetLoader.onLoaded(element, ev);
        };
        const onError = (ev) => {
            ev.target.removeEventListener(events[tagName], onLoaded);
            ev.target.removeEventListener('error', onError);
            zdlib.assetLoader.onError(element, ev);
        };
        if (!modifiedValue) {
            if (tagName === 'IMG')
                modifiedValue = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='; // transparent_gif
            await zdlib.assetLoader.onClear(element);
        } else {
            element.addEventListener(events[tagName], onLoaded);
            element.addEventListener('error', onError);
            await zdlib.assetLoader.onLoading(element, modifiedValue);
        }
        //if (!modifiedType && element.closest('#zd_se_spot')) console.log('##....#¤', getModifiedElementType(element) , element)
    }
    element._setAttribute(propName, modifiedValue);
};

/**
 * Modifies HTMLElement.prototype so that relative urls in url() function are converted to absolute
 * when setting style attribute,
 * and when setting properties on style object (CSSStyleDeclaration).
 * In this case a Proxy is returned
 * for the style object, and the proxy intercepts both prop setters and setProperty() method
 */
const fixStyleUrls = () => {
    if (styleGetter) return;
    const proto = HTMLElement.prototype;
    if (!proto._setAttribute) proto._setAttribute = proto.setAttribute;
    styleGetter = Object.getOwnPropertyDescriptor(proto, 'style').get;
    Object.defineProperty(proto, 'style', {
        enumerable: true,
        get() {
            if (this._style) return this._style;
            const style = styleGetter.call(this);
            if (!getModifiedElementType(this)) return style;
            this._style = new Proxy(style, {
                set(target, p, value, receiver) {
                    target[p] = convertCSSurl(null, value);
                    return true;
                },
                get(target, p, receiver) {
                    const value = target ? target[p] : undefined;
                    return typeof value === 'function'
                        ? new Proxy(value.bind(target), {
                            apply(target, thisArg, argArray) {
                                const args = argArray;
                                if (target.name?.includes('setProperty') && argArray.length > 0) {
                                    args[1] = convertCSSurl(null, args[1]);
                                }
                                return typeof target === 'function' && Reflect.apply(target, thisArg, args);
                                }
                            })
                        : value;
                },
            });
            return this._style;
        }
    });
    proto._setStyleAttribute = function (prop, value) {
        this._setAttribute(prop, prop === 'style' && getModifiedElementType(this) ? convertCSSurl(null, value) : value);
    };
    proto.setAttribute = proto._setStyleAttribute;
}


/**
 * Modify src attribute setter for HTMLElement prototype
 * @param zdlib
 * @param proto Element to modify
 * @param propName Src prop name
 */
const fixLocalUrls = (zdlib, proto, propName) => {
    if (!proto._setAttribute) proto._setAttribute = proto.setAttribute;

    Object.defineProperty(proto, propName, {
        enumerable: true,
        configurable: true,
        set(value) {
            void setSrcProp(zdlib, this, propName, value);
        },
    });
    proto.setAttribute = function (prop, value) {
        // data-src is a legacy attribute; => set its value to src prop
        if (prop === 'data-src') this._setAttribute(prop, value);
        if (prop === propName || prop === 'data-src') void setSrcProp(zdlib, this, propName, value);
            else if (prop === 'style') this._setStyleAttribute(prop.value);
                else this._setAttribute(prop, value);
    };
};


/**
 * Make sure XHR urls are absolute
 */
const fixXmlHttpRequest = () => {
    const proto = XMLHttpRequest.prototype;
    if (!proto._open) proto._open = proto.open;
    proto.open = function (method, url, async = true, username = null, password = null) {
        const modifiedUrl = makeUrlAbsolute(url);
        //if (modifiedUrl !== url) console.log('#################\n', decodeURIComponent(url) , '\n==>\n', decodeURIComponent(modifiedUrl));
        this._open(method, modifiedUrl, async, username, password);
    };
};

/**
 * Suppress console log calls when on engage-prod, or if set in URL.
 * Templates sometimes output A LOT
 * @param zdlib
 * @param envName Engage environment, 'test' or 'prod'
 */
const fixConsole = (zdlib, envName) => {
    if (zdlib.env.isTranscoding) return;
    const params = new URLSearchParams(window.location.search);
    const prod = envName === 'prod';
    const muteTemplate = !params.has('enable-log')
        && (prod || params.get('mute-template') !== null);
    console.log = muteTemplate ? () => {} : consoleLog;
    const muteZdlib = !params.has('enable-log') && (prod || params.get('mute-zdlib') !== null);
    zdlib.dbg = muteZdlib ? () => {} : consoleLog;
};



/**
 * Loads a script and adds it to head. This is also available to templates thru zdlib.utils
 * @param params
 * @returns {Promise<unknown>}
 */
const importScript = (params) => {
    return new Promise(function (resolve, reject) {
        const debug = new URLSearchParams(window.location.search).has('debugAssets') || window.transcodeapi_init;
        function loadError(oError) {
            reject('!!! Error loading script: ' + oError.target.src);
        }
        function loadComplete(ev) {
            if (debug) console.log(`%cImported JS:`, 'color: blue; background-color: cyan',  params.url);
            resolve(params);
        }

        if (document.head.querySelector(`script[src="${params.url}"]`)) {
            resolve(params);
            return;
        }
        const script = document.createElement('script');
        script.onerror = loadError;
        script.onload = loadComplete;
        if (params.id) script.id = params.id;
        script.setAttribute('data-zd-imported', params.importedId || '');
       /* if (params.module) script.setAttribute('type', 'module');
        else if (params.defer) script.setAttribute('defer', '');*/
        params.attributes.forEach(({name, value}) => {
            script.setAttribute(name, value);
        })
        document.head.appendChild(script);
        const url = params.url;//.replace(/\?V=.+/g, '');
        if (debug) console.log(`%cImport JS:`, 'color: yellow; background-color: green', url);
        script.src = makeUrlAbsolute(url);
    });
};

const importCss = (params) => {
    return new Promise(function (resolve, reject) {
        const debug = new URLSearchParams(window.location.search).has('debugAssets') || window.transcodeapi_init;
        if (document.head.querySelector(`link[href="${params.url}"]`)) {
            if (debug) console.log(`%cImported CSS:`, 'color: blue; background-color: cyan', params.url);
            resolve(params);
            return;
        }
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.setAttribute('data-zd-imported', params.importedId || '');
        link.setAttribute('type', 'text/css');
        params.attributes.forEach(({name, value}) => {
            link.setAttribute(name, value);
        })
        if (debug) console.log(`%cImport CSS:`, 'color: yellow; background-color: green', params.url);
        document.head.appendChild(link);
        link.onload = () => {
            resolve(params);
        };
        link.onerror = () => {
            //reject(`Error loading CSS: ${params.url}`);
            console.warn(`Error loading CSS: ${params.url}`, link);
            resolve();
        };
        link.href = params.url;
    });
};

/**
 * Loads fonts using WebFont. Available to templates
 * @param params
 * @returns {Promise<unknown>}
 */
function loadFonts(params) {
    return new Promise(function (resolve, reject) {
        const config = {
            custom: {
                families: params.families,
            },
            active: function () {
                //console.log('** all fonts loaded')
                resolve(params);
            },
            fontactive: function (familyName) {
                //console.log('.. font loaded:', familyName)
                if (params.onLoaded) params.onLoaded(familyName);
            },
            fontinactive: function (familyName) {
                //console.log('!! font load error:', familyName)
                if (params.onError) params.onError(familyName);
            },
        };
        if (params.urls?.length) {
            config.custom.urls = params.urls.map((url) => makeUrlAbsolute(url));
        }

        WebFont.load(config);
    });
}

